
$base: #00336e;
$primary: #00336e;
$secondary: #1783fb;
$tertiary: #c38cff;
$danger: #f63;
$warning: #ffbe4f;
$success: #539987;
$gray: #6b7280;
$dark: #463cb4;
$white: #fff;

$table-hover-bg-factor: 0.3;
$pagination-hover-bg: $gray !default;

$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $tertiary,
    'warning': $warning,
    'danger': $danger,
    'dark': $dark,
    'light': $gray,
    'white': $white 
);

@import '~bootstrap/scss/bootstrap';